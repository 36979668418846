import React, {Component} from 'react';
import Modal from '@material-ui/core/Modal';
import DashboardTranslations from '../../DashboardTranslations.js';
import close from '../../images/svg/close.svg'
import '../styles/seekerMessageModal.scss'
import {getOnlineIVInfo} from "../helperFunctions/dataServices";
import {getErrorMessage} from "../../messages/errors";

class SeekerMessageModal extends Component {
  constructor(props) {
    super(props);
    this.state = this.getInitialState()
  }

  getInitialState() {
    return {
      online_iv_detail: {
        submitted: false,
        selected: true,
        sms_type: 'online_iv_detail',
        extra_data: {
          online_iv_platform: '',
          online_iv_url: '',
          online_iv_id: '',
          online_iv_password: '',
        },
        inputError: '',
        urlError: ''
      },
      errorMessage: '',
      dataUpdated: false
    }
  }

  fetchData(applicationId){
    getOnlineIVInfo(applicationId).then((response) => {
      if (response && response.status >= 200 && response.status < 300) {
        this.updateState(response.data)
      } else {
        this.setState({
          loading: false,
          showError: true,
          error: response.response.data.error ? getErrorMessage('from_api_response', {'apiErrorMessage': response.response.data.error}) : ""
        })
      }
    })
      .catch((e) => {
        this.setState({loading: false, showError: true})
      })
  }

  componentDidUpdate(prevProps) {
    if(this.props.showMessageModal && this.props.showMessageModal === true) {
      if ((this.props.applicationId) && ((this.props.applicationId !== prevProps.applicationId) || (!prevProps.showMessageModal && this.props.showMessageModal))) {
        this.fetchData(this.props.applicationId);
      }
    }
  }

  updateState(seekerInfo) {
    this.setState(this.getInitialState())
    let online_iv_detail = {...this.state['online_iv_detail']};
    online_iv_detail.extra_data = {
      online_iv_platform: seekerInfo.online_iv_platform || '',
      online_iv_url: seekerInfo.online_iv_url || '',
      online_iv_id: seekerInfo.online_iv_id || '',
      online_iv_password: seekerInfo.online_iv_password || '',
    }
    this.setState({online_iv_detail})
  }

  confirmAction() {
    if(!this.state.dataUpdated) {
      this.setState({...this.state, ...{errorMessage: '空欄に値を入力してください。'}})
      return
    }
    let selectedInputs = JSON.parse(JSON.stringify(Object.values(this.state).filter(smsType => smsType.selected && !smsType.submitted)))

    if(selectedInputs.length === 1 && selectedInputs[0].sms_type === 'online_iv_detail' && !Object.values(selectedInputs[0].extra_data).some(y => y.length)) {
      this.setState({...this.state, ...{errorMessage: '空欄に値を入力してください。'}})
      return
    }
    let error = false;
    for (let input of selectedInputs) {

      delete input.submitted
      delete input.selected
      delete input.errorMessage
      delete input.inputError
      delete input.urlError

      if (input.sms_type === "online_iv_detail") {
        if (
          input.extra_data.online_iv_platform === '' && (
            input.extra_data.online_iv_id !== '' || input.extra_data.online_iv_url !== '' || input.extra_data.online_iv_password !== '')
        ) {
          error = true
          this.state.online_iv_detail.inputError = "空欄に値を入力してください。"
        }

        if (input.extra_data.online_iv_url.length == 0 || !this.isValidURL(input.extra_data.online_iv_url)) {
          error = true
          this.state.online_iv_detail.urlError = "正しいURLを入力してください。"
        }
      }
    }
    if (error) {
      this.setState({...this.state, ...{errorMessage: '空欄に値を入力してください'}})
    } else {
      selectedInputs = this.state['online_iv_detail'].extra_data
      this.props.confirmAction(selectedInputs)
    }
  }

  isValidURL(string) {
    let res = string.match(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g);
    return (res !== null)
  };

  // clearAndClose () {
  //   this.setState(this.getInitialState())
  //   this.props.handleClose()
  // }

  render() {
    return (
      <div>
        <Modal
          open={this.props.showMessageModal}
          onClose={this.props.handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          style={{overflowY: "scroll", alignContent: 'center'}}
          BackdropProps={{
            style: {
              position: 'absolute'
            }
          }}
        >
          <div className="seeker-message-modal-container">
            <div className="responsive-header" style={{backgroundColor: "#E3E3E3"}}>
              <div className="login-header-text">
                {this.props.headerText ? this.props.headerText : DashboardTranslations.confirm_modal_header_text}
              </div>
              <div className="burger" style={{border: "0.75px solid #0A57BF", borderRadius: "50%"}}
                   onClick={() => this.props.handleClose()}>
                <img src={close} alt="close"/>
              </div>
            </div>
            <div className="options-container" style={{paddingTop: 5, width: "90%", margin: "auto"}}>
              <div style={{color: "red", fontSize: 13, textAlign: "center", padding: 10}}>
                {this.state.errorMessage}
              </div>
              <div style={{marginTop: 10}}>
                <div
                    key={"9"}
                    style={{
                      alignItems: "center",
                      padding: "5px 15px 5px 0px",
                      fontSize: 13,
                      boxSizing: "border-box"
                    }}
                    className="responsive-display"
                >
                  <div style={{letterSpacing: "0px", color: "#9F9F9F", opacity: 1}} className="responsive-width">サービス＊：</div>
                  <div style={{width: "100%"}}>
                    <input
                        type="text"
                        placeholder="例：Skype, Zoom, Google meet 等"
                        style={{
                          border: "1px solid #D9D9D9",
                          borderRadius: "5px",
                          opacity: 1,
                          padding: 5,
                          width: "95%",
                          paddingRight: 5,
                          paddingLeft: 5
                        }}
                        value={this.state.online_iv_detail.extra_data.online_iv_platform}
                        onChange={(e) => this.setState({
                          dataUpdated: true,
                          errorMessage: '',
                          'online_iv_detail': {
                            sms_type: 'online_iv_detail',
                            selected: true,
                            extra_data: {...this.state.online_iv_detail.extra_data, ...{online_iv_platform: e.target.value}},
                            inputError: ''
                          }
                        })}
                    />
                    {this.state.online_iv_detail.inputError && <div style={{color: "red", fontSize: 13, textAlign: "left", padding: 10}}>
                      {this.state.online_iv_detail.inputError}
                    </div>}
                  </div>
                </div>

                <div
                    key={"10"}
                    style={{
                      alignItems: "center",
                      padding: "5px 15px 5px 0px",
                      fontSize: 13,
                      boxSizing: "border-box",
                      marginTop: 10
                    }}
                    className="responsive-display"
                >
                  <div style={{letterSpacing: "0px", color: "#9F9F9F", opacity: 1}} className="responsive-width">URL＊：</div>
                  <div style={{width: "100%"}}>
                    <input
                        type="text"
                        style={{
                          border: "1px solid #D9D9D9",
                          borderRadius: "5px",
                          opacity: 1,
                          padding: 5,
                          width: "95%"
                        }}
                        value={this.state.online_iv_detail.extra_data.online_iv_url}
                        onChange={(e) => this.setState({
                          dataUpdated: true,
                          errorMessage: '',
                          'online_iv_detail': {
                            sms_type: 'online_iv_detail',
                            selected: true,
                            extra_data: {...this.state.online_iv_detail.extra_data, ...{online_iv_url: e.target.value}},
                            urlError: ''
                          }
                        })}
                    />
                    {this.state.online_iv_detail.urlError && <div style={{color: "red", fontSize: 13, textAlign: "left", padding: 10}}>
                      {this.state.online_iv_detail.urlError}
                    </div>}
                  </div>
                </div>

                <div
                    key={"11"}
                    style={{
                      alignItems: "center",
                      padding: "5px 15px 5px 0px",
                      fontSize: 13,
                      boxSizing: "border-box",
                      marginTop: 10
                    }}
                    className="responsive-display"
                >
                  <div style={{letterSpacing: "0px", color: "#9F9F9F", opacity: 1}} className="responsive-width">ID：</div>
                  <div style={{width: "100%"}}>
                    <input
                        type="text"
                        style={{
                          border: "1px solid #D9D9D9",
                          borderRadius: "5px",
                          opacity: 1,
                          padding: 5,
                          width: "95%"
                        }}
                        value={this.state.online_iv_detail.extra_data.online_iv_id}
                        onChange={(e) => this.setState({
                          dataUpdated: true,
                          errorMessage: '',
                          'online_iv_detail': {
                            sms_type: 'online_iv_detail',
                            selected: true,
                            extra_data: {...this.state.online_iv_detail.extra_data, ...{online_iv_id: e.target.value}}
                          }
                        })}
                    />
                  </div>
                </div>

                <div
                    key={"12"}
                    style={{
                      alignItems: "center",
                      padding: "5px 15px 5px 0px",
                      fontSize: 13,
                      boxSizing: "border-box",
                      marginTop: 10
                    }}
                    className="responsive-display"
                >
                  <div style={{letterSpacing: "0px", color: "#9F9F9F", opacity: 1}} className="responsive-width">パスワード：</div>
                  <div style={{width: "100%"}}>
                    <input
                        type="text"
                        style={{
                          border: "1px solid #D9D9D9",
                          borderRadius: "5px",
                          opacity: 1,
                          padding: 5,
                          width: "95%"
                        }}
                        value={this.state.online_iv_detail.extra_data.online_iv_password}
                        onChange={(e) => this.setState({
                          dataUpdated: true,
                          errorMessage: '',
                          'online_iv_detail': {
                            sms_type: 'online_iv_detail',
                            selected: true,
                            extra_data: {...this.state.online_iv_detail.extra_data, ...{online_iv_password: e.target.value}}
                          }
                        })}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="button-container">
              <button className="btn btn-secondary"
                      onClick={() => this.props.handleClose()}>{this.props.cancelButtonText ? this.props.cancelButtonText : DashboardTranslations.confirm_modal_cancel_button_text}</button>
              <button className="btn btn-primary"
                      onClick={() => this.confirmAction()}>{this.props.confirmButtonText ? this.props.confirmButtonText : '送信する'}</button>
            </div>
          </div>
        </Modal>
      </div>
    )
  }
}

export default SeekerMessageModal;
