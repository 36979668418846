import React, { Component } from 'react';
import { connect } from 'react-redux';

import modalImage from '../../images/modals/infoModalAddSlotCalendar.png';
import closeIcon from '../../images/svg/close-icon-with-circle.svg';

import './infoModalAddSlotCalendar.scss';

class InfoModalAddSlotCalendar extends Component {
    render() {
        return (
            <div className="info-modal-add-slot-calendar">
                <div className="modal-outer-container">
                    <img className="info-image-1" src={modalImage}  alt="ss" />
{/* 
                    <button className="btn" onClick={() => this.props.closeInfoModalAddSlotCalendar()}>
                        閉じる
                    </button> */}
                    <img className="close-icon" src={closeIcon}  alt="ss" onClick={() => this.props.closeInfoModalAddSlotCalendar()}/>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        isLoggedIn: state.user.isLoggedIn,
    }
};

export default connect(mapStateToProps)(InfoModalAddSlotCalendar);
